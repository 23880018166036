.image_container {
  background-color: #1a1818;
}

.container_banner {
  position: relative;
}

.mainContauner {
  background-color: #1a1818;
}

.bg_image {
  /* position: absolute; */
  width: 100%;
  background-color: #1a1818;
}

.bg_no_image {
  height: 1000px;
  width: 100%;
  background-color: #1a1818;
}

.bg_no_image_small {
  width: 100%;

  height: 840px;
  background-color: #1a1818;
}

.bg_image_small {
  /* position: absolute; */
  width: 100%;

  height: 840px;
  background-color: #1a1818;
}

.info_container {
  position: absolute;
  display: flex;
  margin: 0px 85px 0px 85px;
  justify-content: center;
  flex-direction: column;
  top: 30%;
  left: 0%;
  /* padding-top: 15%; */
}
.info_container_small {
  position: absolute;
  display: flex;
  margin: 0px 25px 0px 25px;
  height: 840px;
  padding-top: 100px;
  justify-content: flex-start;
  flex-direction: column;
  top: 0%;
  left: 0%;
}

.title {
  /* z-index: 1; */
  color: #fff;
  font-family: "Manrope";
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  width: 70%;
}

.title_small {
  color: #fff;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  /* z-index: 1; */
  margin-bottom: 115px;
}

.invisible_title {
  opacity: 0;
  margin-bottom: -30px;
  /* display: none; */
}

.main_title_mobile {
  color: #fff;
  font-family: "Manrope";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  z-index: 0;
  margin-bottom: 115px;
}

.header_container {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.header_container_small {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  width: 90%;
  z-index: 1;
  top: 40px;
}

.header_title {
  color: #ffe500;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
}

.logo_small {
  width: 150px;
}

.modal_container {
  z-index: 5;
}

.input {
  border-radius: 14px;
  border: 1px solid rgba(161, 159, 159, 0.3);

  background: rgba(6, 6, 6, 0.82);

  box-shadow: 0px 0px 5px 0px rgba(180, 177, 177, 0.25);
  backdrop-filter: blur(6px);
  padding: 15px;
  margin-bottom: 20px;
  color: #fff;
}

.button_style_modal {
  border-radius: "14px";
  border: 1px solid rgba(161, 159, 159, 0.3);
  background-color: rgba(6, 6, 6, 0.82);
  box-shadow: 0px 0px 5px 0px rgba(180, 177, 177, 0.25);
  backdrop-filter: blur(6px);
  width: 50px;
  color: "#fff";
}
