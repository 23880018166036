.driver_menu {
  position: absolute;
  background-color: rgba(26, 23, 23, 0.95);
  width: 80%;
  height: 100%;
  z-index: 10;
  padding: 50px 10px 10px 0px;
  transition-property: all;
  transition-duration: 0.7s;
  transform: translateX(-100vw);
}

.driver_menu_logo {
  display: flex;
  justify-content: center;
}

.driver_menu_item {
  list-style: none;
  color: #ffe500;
  font-family: "Manrope";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.driver_menu_item_active {
  list-style: none;
  color: #fff;
  font-family: "Manrope";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.driver_menu_text_container {
  margin-top: 50px;
  margin-left: 50px;
}

.driver_menu_animated {
  position: absolute;
  background-color: rgba(26, 23, 23, 0.95);
  width: 80%;
  height: 100%;
  z-index: 10;
  padding: 50px 10px 10px 0px;
  transition-property: all;
  transition-duration: 0.7s;
  transform: translateX(0vw);
}

.button_container {
  margin-left: 50px;
  margin-top: 50px;
}

.contact_container {
  margin-left: 50px;
  margin-top: 30px;
}

.contact_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.phone_icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.tiktok_icon_draver {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  background-color: #ffe500;
  border-radius: 6px;
}

.driver_menu_item_contact {
  color: #ffe500;
  text-align: right;
  font-family: "Manrope";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration-line: underline;
}
.driver_menu_item_instagram {
  color: #ffe500;
  text-align: right;
  font-family: "Manrope";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
}
