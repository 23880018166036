.services_text_small_phone {
  color: #fff;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  position: absolute;
  top: 65%;
  left: 12%;
  width: 250px;
}

.container_type {
  padding-top: 100px;
}

.footer_container_type {
  margin-top: 100px;
}
