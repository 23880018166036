.modal_title {
  color: #fff;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.6%; /* 38.58px */
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 22px;
  margin-top: 40px;
}

.modal_subtitle {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 128.6%;
  margin: 0;
  margin-bottom: 32px;
}

.modal_title_success {
  color: #fff;
  font-family: "Manrope";
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 128.6%; /* 38.58px */
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 22px;
  margin-top: 90px;
}

.modal_subtitle_success {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.modal_subtitle_success_second {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 120px;
}

.input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.close_icon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.error_name {
  margin-top: -19px;
  margin-left: 10px;
  color: rgba(237, 14, 14, 0.979);
  font-family: "Manrope";
}

.error_number {
  margin-left: 10px;
  color: rgba(237, 14, 14, 0.979);
  font-family: "Manrope";
}
