.mainContauner {
  background-color: #1a1818;
}

.service_desc_title {
  color: #ffe500;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.6%; /* 38.58px */
  text-transform: uppercase;
  padding-top: 250px;
  padding-left: 50px;
  margin-top: 0px;
}

.service_desc_title_small {
  color: #ffe500;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%; /* 20.576px */
  text-transform: uppercase;
  padding-top: 120px;
  padding-left: 20px;
  margin-top: 0px;
}

.service_desc_img_container {
  display: flex;
  justify-content: center;
}
.service_desc_img {
  width: 95%;
}

.service_desc_under_title {
  color: #999;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.6%; /* 38.58px */
  padding: 20px 50px 0px 50px;
}

.service_desc_under_title_small {
  color: #999;
  font-family: "Manrope";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.6%; /* 15.432px */
  padding: 0px 20px 0px 20px;
}

.service_desc_description {
  color: #999;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  padding: 20px 50px 0px 50px;
}

.service_desc_description_small {
  color: #999;
  font-family: "Manrope";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%; /* 15.432px */
  padding: 0px 20px 0px 20px;
}

.service_desc_price {
  color: #999;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  padding: 0px 50px 0px 50px;
  margin-top: 0px;
}

.service_desc_price_small {
  color: #999;
  font-family: "Manrope";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%; /* 15.432px */
  margin-top: -10px;
  padding: 0px 20px 0px 20px;
}

.service_desc_material {
  color: #999;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  padding: 0px 50px 0px 50px;
  margin-top: 0px;
}

.service_desc_material_small {
  color: #999;
  font-family: "Manrope";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%; /* 15.432px */
  margin-top: -10px;
  padding: 0px 20px 0px 20px;
}

.service_desc_type {
  color: #999;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  padding: 0px 50px 0px 50px;
  margin: 0px;
}

.service_desc_type_small {
  color: #999;
  font-family: "Manrope";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%; /* 15.432px */
  margin-top: -10px;
  padding: 0px 20px 0px 20px;
}

.service_type_container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.service_btn_container {
  padding-left: 50px;
  padding-bottom: 300px;
}

.service_desc_ps {
  color: #999;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 128.6%;
  padding: 0px 50px 0px 50px;
}

.service_btn_container_small {
  padding-left: 20px;
  padding-bottom: 100px;
}

.service_desc_ps_small {
  color: #999;
  font-family: "Manrope";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%; /* 15.432px */
  padding: 0px 20px 0px 20px;
}
