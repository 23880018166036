.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 95%;
}

.menu_item {
  list-style: none;
  margin-left: 35px;
  color: #ffe500;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.menu_item_active {
  list-style: none;
  margin-left: 35px;
  color: #ffffff;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.menu_item:hover {
  transition-property: all;
  transition-duration: 0.5s;
  transform: scale(1.2);
  cursor: pointer;
}

.phone {
  margin-left: 40px;
  color: #ffe500;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}

.phone:hover {
  cursor: default;
}

.menu_options {
  display: flex;
  flex-direction: row;
}

.logo {
  margin-bottom: 40px;
}

.header_phone_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.phone_icon_header {
  width: 24px;
  height: 24px;
  margin-right: -30px;
}

.logo:hover {
  cursor: pointer;
}

.remove_underline {
  text-decoration: none;
}
