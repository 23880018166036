.second_block_small {
  /* margin-top: -120px; */
}

.second_block_title_small {
  color: #fff;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 28px;
  margin-right: 28px;
  position: absolute;
  /* top: 80%; */
}

.second_block_first_image_small {
  width: 100%;
  margin-top: -200px;
}

.second_block_text_small {
  color: #fff;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  margin-left: 28px;
  margin-right: 28px;
  /* margin-top: -100px; */
}

.second_block_second_image_small {
  width: 100%;
  margin-top: -50px;
}

.second_block_img_container_first {
  position: relative;
}
