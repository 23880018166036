.footer_container {
  width: 100%;
  background-color: #121010;
  padding-top: 50px;
  padding-bottom: 70px;
}

.footer_text_container {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.footer_text {
  color: #e2e2e2;
  font-family: "Manrope";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
}

.footer_text_adress {
  color: #e2e2e2;
  font-family: "Manrope";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 15px;
}

.footer_text_weekend {
  color: #e2e2e2;
  font-family: "Manrope";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 30px;
}

.footer_text_number {
  color: #e2e2e2;
  font-family: "Manrope";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 30px;
}

.instagram_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.instagram_icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.tiktok_icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 6px;
}

.footer_container_icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer_img {
  margin-right: 10%;
  margin-top: 3%;
  opacity: 0.1;
}

.footer_menu {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
}

.footer_menu_option {
  color: #e2e2e2;
  font-family: "Manrope";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  margin-right: 55px;
}

.footer_menu_option:hover {
  cursor: pointer;
}

.footer_title_map {
  color: #fff;
  font-family: "Manrope";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.6%; /* 18.004px */
  text-decoration-line: underline;
  margin-left: 55px;
}

.footer_bussines_container {
  margin-top: 50px;
  margin-left: 50px;

  display: flex;
  flex-direction: column;
}

.footer_bussines_info {
  color: #e2e2e2;
  font-family: "Manrope";
  font-size: 12px;
  font-weight: 700;
  margin-right: 55px;
}

.footer_bussines_info_second {
  color: #e2e2e2;
  font-family: "Manrope";
  font-size: 12px;
  font-weight: 300;
  margin-right: 55px;
}
