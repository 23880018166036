.BG_image {
  position: absolute;
  background-color: #1a1818;
  opacity: 0.1;
  width: 100%;
  height: 80%;
}

.container_price_list {
  background-color: #1a1818;
  width: 100%;
  /* height: 100%; */
  position: absolute;
  /* padding-bottom: 100px; */
  z-index: 10;
}

.price_list_title_big {
  color: #ffe500;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.6%; /* 38.58px */
  text-transform: uppercase;
  margin-top: 250px;
  margin-left: 55px;
}

.price_list_title_small {
  color: #ffe500;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%; /* 38.58px */
  text-transform: uppercase;
  margin-top: 150px;
  margin-left: 20px;
}

.sound_isolation_title_container {
  padding-bottom: 30px;
  border-bottom: #ffe500 solid 1px;
  margin-left: 55px;
  margin-right: 55px;
  margin-top: 70px;
}

.sound_isolation_title_container_small {
  padding-bottom: 20px;
  border-bottom: #ffe500 solid 1px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
}

.dry_clean_title_container {
  padding-bottom: 30px;
  margin-left: 55px;
  margin-right: 55px;
  margin-top: 70px;
}

.dry_clean_title_container_small {
  padding-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
}

.sound_isolation_title {
  color: #d1d1d1;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.6%; /* 38.58px */
  text-transform: uppercase;
}

.sound_isolation_title_small {
  color: #d1d1d1;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%; /* 38.58px */
  text-transform: uppercase;
}

.item_title {
  color: #919191;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.item_title_price {
  color: #919191;
  font-family: "Manrope";
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.item_title_small {
  color: #919191;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.item_price {
  color: #919191;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: default;
  /* pomanrope-events: none; */
}

.item_price_price {
  color: #919191;
  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  pomanrope-events: none;
  margin-bottom: 10px;
}

.item_price_small {
  text-align-last: right;
  color: #919191;
  font-family: "Manrope";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  min-width: 100px;
}

.item_container {
  display: flex;
  justify-content: space-between;
  border-bottom: #ffe500 solid 1px;
  margin: 0 55px 0 55px;
  padding-bottom: 10px;
  padding-top: 5px;
}

.item_container_small {
  display: flex;
  justify-content: space-between;
  border-bottom: #ffe500 solid 1px;
  margin: 0 20px 0 20px;
  padding-bottom: 10px;
  padding-top: 5px;
}

.icon_open {
  margin-left: 10px;
  width: 10px;
  height: 10px;
  filter: invert(70%);
  transition-property: all;
  transition-duration: 0.5s;
  transform: rotate(360deg);
  /* fill: red; */
}

.open_options_invisible {
  transition-property: all;
  transition-duration: 0.5s;
  transform: scale(0.2);
  opacity: 0;
  position: absolute;
  right: 55px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 300px;
  height: 100px; */
  background-color: rgba(26, 23, 23, 0.95);
  z-index: 10;
  border-radius: 20px;
}

.open_options {
  transition-property: all;
  transition-duration: 0.5s;
  transform: scale(1);
  opacity: 1;
  position: absolute;
  right: 55px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 300px;
  height: 100px; */
  background-color: rgba(26, 23, 23, 0.95);
  z-index: 10;
  border-radius: 20px;
}

.margin {
  margin-bottom: 30px;
}

.footer_bg {
  margin-top: 100px;
  margin-bottom: -100px;
}

.footer_bg_small {
  margin-top: 50px;
  bottom: 100px;
}

.item_hidden_container {
  z-index: 9;
  min-width: 100px;
}

.item_hidden_container:hover {
  cursor: poManrope;
}

.icon_open_animation {
  margin-left: 10px;
  width: 10px;
  height: 10px;
  filter: invert(70%);
  transition-property: all;
  transition-duration: 0.5s;
  transform: rotate(180deg);
}

.open_options__small {
  transition-property: all;
  transition-duration: 0.5s;
  transform: scale(1);
  opacity: 1;
  position: absolute;
  right: 20px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* width: 300px;
  height: 100px; */
  background-color: rgba(26, 23, 23, 0.95);
  z-index: 10;
  border-radius: 20px;
}
