.second_block {
  background-color: #1a1818;
  /* margin-top: 40%; */
}

.second_block_title_container {
  display: flex;
  flex-direction: row;
  margin: 0px 80px 0px 80px;
}

.second_block_title_yellow {
  color: #ffe500;
}
.second_block_title {
  color: #fff;
  font-family: "Manrope";
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}

.second_block_first_image {
  margin-top: -400px;
  margin-left: 60px;
  width: 40%;
  height: 1000px;
  /* margin-bottom: 300px; */
  /* flex-shrink: 0; */
}

.second_block_image_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.second_block_text {
  color: #fff;
  font-family: "Manrope";
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  /* max-width: 650px; */
}

.second_block_right {
  margin-left: 60px;
  margin-right: 60px;
}

.second_block_second_image {
  /* margin-top: -400px; */
  margin-left: 60px;
  width: 80%;
  margin-bottom: 100px;
  /* flex-shrink: 0; */
}
