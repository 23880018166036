.review_container {
  margin-top: 100px;
  margin-bottom: 200px;
}

.portfolio_title {
  color: #ffe500;
  font-family: "Manrope";
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.6%; /* 70.73px */
  text-transform: uppercase;
  margin-left: 50px;
}

.corusel_container_review {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px;
  align-items: center;
}

.img_corusel_review {
  transition-property: all;
  transition-duration: 1s;
  width: 90%;
}
.img_corusel_prev_review {
  transition-property: all;
  transition-duration: 1s;
  width: 60%;
}

.img_corusel_next_review {
  transition-property: all;
  transition-duration: 1s;
  width: 60%;
}

.img_corusel_animated {
  transition-property: all;
  transition-duration: 0.5s;
  width: 40%;
}

.left_arrow_review_big {
  position: absolute;
  left: 30%;
  z-index: 10;
}

.right_arrow_review_big {
  position: absolute;
  right: 30%;
  z-index: 10;
}

.prev_review {
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
}

.current_review {
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.next_review {
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
}

.animated_prev_review {
  transition-property: all;
  transition-duration: 0.5s;
  transform: translateX(37vw) scale(1.1);
  opacity: 1;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.animated_center_prev_review {
  transition-property: all;
  transition-duration: 0.5s;
  transform: translateX(34vw) scale(0.9);
  opacity: 0.3;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.animated_center_on_prev_next_review {
  transition-property: all;
  transition-duration: 0.5s;
  transform: translateX(34vw);
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.animated_prev_on_next_review {
  transition-property: all;
  transition-duration: 0.5s;
  transform: translateX(-34vw);
  opacity: 0.3;
  margin-right: -43px;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.animated_center_prev_on_next_review {
  transition-property: all;
  transition-duration: 0.5s;
  transform: translateX(-34vw) scale(0.9);
  opacity: 0.3;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.animated_next_on_next_review {
  transition-property: all;
  transition-duration: 0.5s;
  transform: translateX(-34vw) scale(1.1);
  opacity: 1;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  align-items: center;
}

.review_title {
  color: #fff;
  font-family: "Manrope";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.review_desc {
  color: #fff;
  font-family: "Manrope";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
  text-align: center;
}

.desc_review_container {
  display: flex;
  justify-content: center;
  width: 400px;
}

.desc_review_container_middle {
  display: flex;
  justify-content: center;
  width: 300px;
}

.review_desc_side {
  color: #fff;
  font-family: "Manrope";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
  text-align: center;
}
