.services_title_small {
  color: #ffe500;
  font-family: "Manrope";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 20px;
}

.service_img_container_small {
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 30px;
}

.services_text_small {
  color: #fff;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  position: absolute;
  top: 80%;
  left: 12%;
}

.services_img_small {
  width: 100%;
  border-radius: 16px;
}
