.services_container {
  /* margin-top: -200px; */
  margin-left: 50px;
}

.title_services {
  color: #ffe500;
  font-family: "Manrope";
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
}

.option {
  color: #fff;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  margin-right: 70px;
}

.option_active {
  color: #ffe500;
  font-family: "Manrope";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  margin-right: 70px;
}

.option:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.option_services_container {
  margin-top: 50px;
}

.services_img {
  width: 100%;
}

.services_img_container {
  margin-top: 60px;
  padding: 0px 50px 0px 50px;
}

.services_img_title {
  color: #fff;
  font-family: "Manrope";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 0%;
  /* transform: translate(-50%, -50%); */
}

.services_img_margin {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}

.services_img_margin:hover {
  transition-property: all;
  transition-duration: 1s;
  transform: scale(1.05);
}
