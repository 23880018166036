.button {
  background-color: #ffe500;
  padding: 16px;
  max-width: 255px;
  max-height: 60px;
  border-radius: 16px;
  /* z-index: 5; */
}

.button_small {
  background-color: #ffe500;
  padding: 10px;
  max-width: 150px;
  max-height: 30px;
  border-radius: 5px;
  /* z-index: 5; */
}

.button_title {
  color: #1a1818;
  text-align: center;
  font-family: "Manrope";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
}
.button_title_small {
  color: #1a1818;
  text-align: center;
  font-family: "Manrope";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
}

.button:hover {
  transition-property: all;
  transition-duration: 0.5s;
  transform: scale(1.1);
}

.button_title:hover {
  cursor: pointer;
}
