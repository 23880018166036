.footer_title_small {
  color: #ffe500;
  font-family: "Manrope";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 20px;
}

.footer_img_container_small {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
}

.footer_img_small {
  opacity: 0.1;
  width: 100%;
}

.footer_text_container_small {
  position: absolute;
  left: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
}

.footer_map_container_small {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin-top: 150px;
  padding-bottom: 20px;
}

.footer_title_map_container_small {
  width: 100%;
}

.footer_title_map_small {
  color: #fff;
  font-family: "Manrope";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.6%; /* 18.004px */
  text-decoration-line: underline;
  margin-left: 20px;
}

.img_map_small {
  width: 95%;
  margin-top: 15px;
}

.footer_menu_option_small {
  color: #e2e2e2;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.footer_container_menu_small {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.footer_text_number_small {
  color: #e2e2e2;
  font-family: "Manrope";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.6%; /* 14.146px */
  text-transform: capitalize;
  text-decoration-line: underline;
}

.footer_contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.phone_icon_footer {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.tiktok_icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 6px;
}

.footer_bussines_container {
  padding-bottom: 50px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
}

.footer_bussines_info {
  color: #e2e2e2;
  font-family: "Manrope";
  font-size: 12px;
  font-weight: 700;
  margin-right: 55px;
}

.footer_bussines_info_second {
  color: #e2e2e2;
  font-family: "Manrope";
  font-size: 12px;
  font-weight: 300;
  margin-right: 55px;
}
