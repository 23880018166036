body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url("./font/Manrope/Manrope-Bold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url("./font/Manrope/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url("./font/Manrope/Manrope-ExtraLight.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url("./font/Manrope/Manrope-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url("./font/Manrope/Manrope-Medium.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url("./font/Manrope/Manrope-Regular.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url("./font/Manrope/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 700;
}
