.portfolio_container_small {
  margin-top: 45px;
}

.portfolio_title_small {
  color: #ffe500;
  font-family: "Manrope";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.6%; /* 30.864px */
  text-transform: uppercase;
  margin-left: 20px;
}

.slider_container_small {
  padding: 45px;
}

.item {
  /* display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  width: "40%"; */
}
