.modal_title_small {
  color: #fff;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 128.6%; /* 20.576px */
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 15px;
}

.modal_subtitle_small {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 128.6%;
  margin: 0;
  margin-bottom: 13px;
}

.input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.close_icon_small {
  position: absolute;
  top: 18px;
  right: 18px;
  width: 18px;
  height: 18px;
}

.modal_title_success_small {
  color: #fff;
  font-family: "Manrope";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 128.6%; /* 38.58px */
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 22px;
  margin-top: 90px;
}

.modal_subtitle_success_small {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.modal_subtitle_success_second_small {
  color: #fff;
  text-align: center;
  font-family: "Manrope";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.6%;
  margin: 0;
  text-transform: uppercase;
  margin-bottom: 120px;
}
