.review_img_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.review_img_small {
  width: 100%;
}

.review_img_padding {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.padding {
  width: 300px;
}

.review_desc_small {
  color: #fff;

  font-family: "Manrope";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
  text-align: center;
}
